.digiTopNavbar {
    justify-content: center !important;
}

.digiTopNavbar .navbar-collapse {
    background: #fff !important;
}

.digiTopNavbar .navbar-toggler:focus {
    shadow: none !important;
}