.digiButtonAction {
    display: inline-block;
}

.digiButtonAction a {
    display: inline-block;
    text-align: center;
    background-color: #324D1A;
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 45px;
    border-radius: 50px;
    box-shadow: 5px 5px 15px #00000033;
    transition: background-color 0.3s ease;
}
.digiButtonAction:hover a {
    background-color: #BECF4B;
    color: #000;
}

.digiButtonAction a span {
    text-transform: uppercase;
    font-size: 1.8rem;
}