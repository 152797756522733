.digiLayout {
    background: #e5e2de;
    height: 100%;
}

/**************
TITLE / Typo
 */
h1 {
    font-size: 2.3rem;
    font-weight: bold;
}

h2 {
    font-size: 1.2rem;
    font-weight: bold;
}

p, li{
    /*font-size: 14px;*/
}

.digiTitreGauche {
    display: inline-block;
    background: url('/src/Assets/img/LigneTitreGauche.svg') no-repeat bottom left;
    background-position-x: -8rem;
    padding-left: 15rem;
    padding-right: 1rem;
    padding-bottom: 30px;
    margin-bottom: 4rem;
}

/***************
NAVIGATION
 */

.digiLeftBar {
    position: fixed;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: 0 8px 10px #b2b2b2;
}

/****************
CONTENT
 */
#digiContent {
    background: url('/src/Assets/img/feuilles_transp.png') no-repeat top left;
    background-size: auto 100vh;
    background-position-x: 6rem;
    background-attachment: fixed;
    padding-top: 11rem;
    padding-bottom: 10px;
    /*padding-left: 15rem;*/
}

.digiContentPaddingLeftRight {
    padding-left: 15rem;
    padding-right: 8rem;
}

/**************
RESPONSIVE
 */
@media screen and (max-width: 720px) {
    #digiContent {
        padding-top: 5rem;
        background-position-x: -15rem;
    }
    .digiTitreGauche {
        background-position-x: -15rem;
        padding-left: 5rem;
        padding-right: .5rem;
    }
    .digiContentPaddingLeftRight {
        padding-left: 5rem;
        padding-right: 3rem;
    }
}