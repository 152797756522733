/***************
HOME PAGE
 */

#digiHomePage {
    min-height: 100vh;
}

.digiDateEvenement {
    display: inline-block;
    width: 100%;
    background-color: #324D1A;
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 50px;
    box-shadow: 5px 5px 15px #00000033;
}

.digiLogoSiao {
    margin-bottom: 10px
}

.digiListeLogosPartenaires {
    list-style: none;
}

.digiListeLogosPartenaires li {
    margin-bottom: 10px;
}

.digiListeLogosPartenaires img {
    width: auto;
    max-width: 100%;
    max-height: 80px;
}

@media screen and (min-width: 200px) and (max-width: 992px) {
    #digiLogoSiao {
        margin-bottom: 40px;
        text-align: center;
    }
}