.digiProgramme {
    background: #fff;
    margin: 3rem 0 5rem 25vw;
    padding: 30px 30px 30px 60px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.digiProgramme ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.digiProgramme li {
    margin-bottom: 1rem;
}
.digiPicto {
    display: inline-block;
    min-width: 30px;
    margin-right: 2rem;
}
.digiProgramme li {
    display: flex;
}
.digiProgramme li strong {
    margin-right: 1rem;
}
.digiProgramme li .digiProgrammeItem {
    display: inline-block;
}


/**********************
RESPONSIVE
 */
@media screen and (max-width: 720px) {
    .digiProgramme {
        margin: 3rem 0 5rem 5vw;
    }
}