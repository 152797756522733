
/*****
LOGO
 */

/*****
NAVIGATION
 */

.digiNav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.digiNav li {
    width: 100%;
    font-size: 1rem;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #eee;
    text-align: center;
}

.digiNav li a {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
}
.digiNav li a.current,
.digiNav li:hover a {
    font-weight: bold;
}
