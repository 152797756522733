#digiHomePage {
    min-height: 100vh;
    background: url('/src/Assets/img/feuilles_homepage.png') no-repeat top right #e5e2de;
    background-size: auto 100vh;
    background-attachment: fixed;
}

.digiEntete h1 {
    font-size: 3.8rem;
}

.digiDiscours section {
    background: #fff;
    margin: 20px;
    padding: 20px;
    font-size: 14px;
}

.digiListeLogosPartenaires ul {
    background: #fff;
    list-style: none;
    margin: 0;
    padding: 8px;
}

.digiListeLogosPartenaires ul li {
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}