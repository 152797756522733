footer {
    font-size: .9rem;
    background: #fff;
    padding: 1.5rem 8rem;
    margin-bottom: 1rem;
}

footer h4 {
    font-size: .9rem;
    font-weight: bold;
    margin-bottom: 0;
}

.digiFooterBox {
    border-left: 3px solid #BECF4B;
}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
footer ul li {
    margin-bottom: 0;
    padding-bottom: 0;
}
footer ul li img {
    max-height: 100%;
}

footer .digiListeLogosPartenaires img {
    max-height: 60px;
}

.digiMentions {
    font-size: .7rem;
    font-weight: bold;
}
.digiMentions a {
    color: #000;
    text-decoration: none;
}
.digiMentions img {
    width: 40px;
}

@media screen and (max-width: 720px) {
    .digiListeLogosPartenaires li {
        flex-basis: 20%;
        text-align: center;
    }
    .digiMentions {
        justify-content: center !important;
        align-items: center !important;
    }
}