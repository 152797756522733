.digiIntervenant {
    background: #fff;
    margin: 3rem 0 5rem 25vw;
    padding: 30px 30px 30px 60px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.digiIntervenant h2 {
    display: block;
    text-align: right;
    padding-right: 5px;
}

.digiIntervenant h3 {
    display: block;
    text-align: right;
    font-size: 14px;
    padding-right: 5px;
}

.digiDescriptionIntervenant {
    border-left: 1px solid #000;
    padding-left: 50px;
}

@media screen and (min-width: 200px) and (max-width: 992px) {
    .digiDescriptionIntervenant {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #000;
        border-left: none;
        padding-left: 0;
        text-align: right;
    }
}

@media screen and (max-width: 720px) {
    .digiIntervenant {
        margin-left: 5vw;
    }
}